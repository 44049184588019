import React,{Component} from "react";
import './index.less'
import {Button} from "antd";
import intl from 'react-intl-universal'
import ajax from "../../utils/ajax";
import {AwesomeButton} from 'react-awesome-button'
import 'react-awesome-button/dist/themes/theme-rickiest.css';
export default class InstallmentTip extends Component{
    constructor() {
        super();
        this.state={
           sending:'',
            tipWord:''
        }
    }

    componentDidMount() {
        this.getTipWord()
    }

    getTipWord(){
        ajax.post('/api/1.0/config/getConfig',{type:'word_change',subType:'installment_window_tip'})
            .then(res=>{
                if(res.code === '0'){
                    this.setState({
                        tipWord:res.data
                    })
                }
            })
    }

    back(){
        this.props.hidden()
    }

    render() {
        return(
            <div className='installmentTipCom'>
                <div className='installment-content'>
                    <div className='confirm-content'>
                        {this.state.tipWord}
                    </div>
                    <div className='confirm-operation'>
                        <div className='got'>
                            <AwesomeButton  onPress={this.back.bind(this)}>Got it</AwesomeButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}