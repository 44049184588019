import React,{Component} from "react";
import './index.less'
import intl from 'react-intl-universal'
import thankPic from '../../images/thank-you-2.svg'
import logoPic from '../../images/logo.png'
import ajax from '../../utils/ajax'

export default class Finish extends Component{
    constructor() {
        super();
        this.state={
            finishContent:''
        }
    }

    componentDidMount() {
        this.getConfigWord()
    }


    getConfigWord(){
        ajax.post('/api/1.0/config/getConfig',{type: "word_change", subType: "end_word"}).then(response=>{
            if(response.code === '0'){
                this.setState({
                    finishContent:response.data
                })
            }
        })
    }

    render() {
        return(
            <div className='finishView'>
                <div className='content'>
                    <img className='thank' src={thankPic}/>
                    <div className='verify-code'>PLEASE COPY THE COMPLETION CODE BACK TO YOUR MTURK PAGE: <span style={{fontSize:"28px"}}>AJFHBG897</span></div>
                    <div className='info' dangerouslySetInnerHTML={{__html:this.state.finishContent}}></div>

                    {/*<img className='logo' src={logoPic}/>    {localStorage.getItem('userCode').substring(12)}*/}
                </div>
            </div>
        )
    }
}