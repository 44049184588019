import React, {Component} from 'react'
import './index.less'
import intl from 'react-intl-universal'
import {Button, Rate,Modal} from 'antd'
import ajax from '../../utils/ajax'
import closePic from '../../images/close.svg'
import showMorePic from '../../images/showmore.svg'
import example from '../../images/12.png'
import PickConfirmCom from '../../components/pickConfirmCom/PickConfirmCom'
import CloseConfirmCom from "../closeConfirmCom/CloseConfirmCom";
import {AwesomeButton} from 'react-awesome-button'
import dateFormat from '../../utils/common'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import 'react-awesome-button/dist/themes/theme-rickiest.css';
import InstallmentTip from "../installmentTipCom/InstallmentTip";
export default class GiftInfoComNew extends Component {
    constructor() {
        super();
        this.state = {
            commentList:[],
            pickConfirmComShow:false,
            tabChoose:1,
            showDisplay:'flex',
            htmlShowCount:0,
            visible:false,
            modalText:'Are you still considering this item?',
            showMoreDisplay:false,
            commentListTemp:[],
            currentPage:1,
            totalPage:0,
            htmlShowHeight:'400px',
            htmlShowOverflow:'hidden',
            htmlShowMore:true,
            tabBorder:'1px rgb(196, 196, 196) solid',
            tabBorder2:'1px #6DBC2F solid',
            installmentTipShow:false,
            installmentLabelShow:false,
            installmentDescription:'',
            installmentDescriptionTitle:'',
            okAll:false
        }
    }

    componentDidMount() {
         this.setInstallment()
        this.getWordSet()
    }

    getWordSet(){
        ajax.post('/api/1.0/config/getConfig',{type:'word_change',subType:'choose_button'})
            .then(res=>{
                if(res.code === '0'){
                    this.setState({
                        chooseButton:res.data
                    })
                }
            })
    }

    async setInstallment(){
        let showResult=0
        if(this.props.item.installmentShow){
            await this.getInstallmentWord()
            this.setState({
                installmentTipShow:true,
                installmentLabelShow:true,
            })
        }

        this.setState({
            okAll:true
        })
    }

    getCommentList(){
        ajax.post('/api/1.0/comments/list',{productCode:this.props.item.giftCode}).then(response=>{
             if(response.code === "0"){
                 let commentList=response.data
                 let isOther=commentList % 3
                 let totalPage
                 if(isOther === 0){
                     totalPage=Math.floor(commentList.length /3)
                 }else{
                     totalPage=Math.floor(commentList.length / 3)+1
                 }
                 let tempList
                 let showMoreDisplay
                 if(totalPage > 1){
                     tempList=commentList.slice(0,3)
                     showMoreDisplay=true
                 }else{
                     tempList=commentList
                     showMoreDisplay=false
                 }
                 this.setState({
                     commentList:response.data,
                     commentListTemp:tempList,
                     totalPage:totalPage,
                     showMoreDisplay:showMoreDisplay,
                     currentPage:1
                 })
             }
        })
    }

    giftInfoComHidden() {
        if(!this.props.smallPopShow){
            this.props.hidden()
        }else{
            this.setState({
                visible:true
            })
        }
        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:0}).then(response=>{

        })
    }

    pickGift() {
       this.pickConfirmComShow()

    }

    notInterested() {
        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:2}).then(response=>{

        })
        this.giftInfoComHidden()
    }

    switchInfo(type){
        let currentTab=this.state.tabChoose
        if(currentTab === 3 && type !=3){
            this.getCommentList()
        }
        if(currentTab === 1 && type !=1){
            this.setState({
                htmlShowHeight:'400px',
                htmlShowOverflow:'hidden',
                htmlShowMore:true
            })
        }
        this.setState({
            tabChoose:type
        })

        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:5+type}).then(response=>{

        })
    }

    pickConfirmComShow(){
        this.setState({
            pickConfirmComShow:true
        })
    }

    pickConfirmComHidden(){
        this.setState({
            pickConfirmComShow:true,
        })
        this.props.hidden()
    }

    showMoreClick(){
        let count=this.state.htmlShowCount
        this.setState({
            htmlShowCount:count+1,
            htmlShowHeight:'auto',
            htmlShowOverflow:'auto',
            htmlShowMore:false
        })
        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:4}).then(response=>{

        })
    }

    prePicShow(){
        console.log('pre picture show!')
    }

    handleOk(){
        console.log('handle ok')
        ajax.post('/api/1.0/giftCloseChoose/add',{giftCode:this.props.item.giftCode,value:1}).then(response=>{

        })
        this.setState({
            visible:false
        })
        this.props.hidden()
    }

    handleCancel(){
        console.log('handle cancel')
        ajax.post('/api/1.0/giftCloseChoose/add',{giftCode:this.props.item.giftCode,value:0}).then(response=>{

        })
        this.setState({
            visible:false
        })
        this.props.hidden()
    }


    closeConfirmHidden(){
        this.setState({
            visible:false
        })
        this.props.hidden()
    }

    showMore(){
        let currentPage=this.state.currentPage
        let totalPage=this.state.totalPage
        let tempList
        let showMoreDisplay
        currentPage=currentPage+1
        if(currentPage < totalPage){
            tempList=this.state.commentList.slice(0,(currentPage)*3)
            showMoreDisplay=true
        }else{
            tempList=this.state.commentList
            showMoreDisplay=false
        }
        this.setState({
            commentListTemp:tempList,
            currentPage:currentPage,
            showMoreDisplay:showMoreDisplay
        })
        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:5}).then(response=>{

        })
    }

    async getInstallmentWord(){
       await ajax.post('/api/1.0/config/getConfig',{type:'word_change',subType:'installment_description'})
            .then(res=>{
                if(res.code === '0'){
                    this.setState({
                        installmentDescription:res.data
                    })
                }
            })
       await ajax.post('/api/1.0/config/getConfig',{type:'word_change',subType:'installment_description_title'})
            .then(res=>{
                if(res.code === '0'){
                    this.setState({
                        installmentDescriptionTitle:res.data
                    })
                }
            })
    }

    installmentTipHidden(){
        this.setState({
            installmentTipShow:false
        })
    }

    render() {
        var p=this
        const params = {
            pagination: {
                el: '.swiper-pagination',
                type: '',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on:{
                slideChange:function () {
                    ajax.post('/api/1.0/choose/add',{giftCode:p.props.item.giftCode,operateType:3}).then(response=>{

                    })
                }
            }
        }
        return (
            <div className='giftInfoCom'>
                {this.state.okAll?<div className='info-content'>
                    <img className='close' src={closePic} onClick={this.giftInfoComHidden.bind(this)}/>
                    <div className='info-show'>
                        <div className='info-header'>
                            <div className='title'>
                                {this.props.item.giftName}
                            </div>
                            <div className='other'>
                                <span className='word'>price:</span>
                                <span className='price'>${this.props.item.price}</span>
                                <span className='rate'><Rate allowHalf disabled defaultValue={this.props.item.commentLevel?((this.props.item.commentLevel - Math.floor(this.props.item.commentLevel)) >= 0.5 ?
                                    (Math.floor(this.props.item.commentLevel)) + 0.5 : Math.floor(this.props.item.commentLevel)):0} /></span>
                            </div>
                        </div>
                        <img className='mainPicture' src={JSON.parse(this.props.item.giftPicture)[0]}/>
                        <div className='info'>
                            {this.state.installmentLabelShow?
                                <div className='installment-description-2'>
                                   <div className='installment-title'>
                                       {this.state.installmentDescriptionTitle}
                                   </div>
                                    <div className='installment-content'>
                                        {this.state.installmentDescription}
                                    </div>
                                </div>
                                :''}

                            <div className='tabs'>
                                <div className='tab1' style={{color:this.state.tabChoose===1?'white':'black',background:this.state.tabChoose===1?'#6DBC2F':null,border:this.state.tabChoose===1?this.state.tabBorder2:this.state.tabBorder,borderRight:'none'}} onClick={this.switchInfo.bind(this,1)}>Info</div>
                                <div className='tab2' style={{color:this.state.tabChoose===2?'white':'black',background:this.state.tabChoose===2?'#6DBC2F':null,border:this.state.tabChoose===2?this.state.tabBorder2:this.state.tabBorder}} onClick={this.switchInfo.bind(this,2)}>Photos</div>
                            </div>
                            {/*<div className='swiper-images'>
                                <Swiper shouldSwiperUpdate={true}  {...params}>
                                    {JSON.parse(this.props.item.giftPicture).map((item,index)=>{
                                        return <img  key={index} src={pictureUrl.concat(item)}/>
                                    })}
                                </Swiper>
                            </div>*/}

                            {
                                this.state.tabChoose === 1?
                                    <div className='info-html-show'>
                                      <div className='info-html' style={{height:this.state.htmlShowHeight,overflow:this.state.htmlShowOverflow}}>
                                        <div className='gift-description' dangerouslySetInnerHTML={{__html:this.props.item.showHtml}}></div>
                                       </div>
                                        {this.state.htmlShowMore?<div className='html-show-more'><span className='word' onClick={this.showMoreClick.bind(this)}>show more</span><img src={showMorePic}/> </div>:''}
                                    </div>
                                : this.state.tabChoose === 2?<div className='swiper-images'>
                                        <Swiper shouldSwiperUpdate={true}  {...params}>
                                            {JSON.parse(this.props.item.giftPicture).map((item,index)=>{
                                                return <img  key={index} src={item}/>
                                            })}
                                        </Swiper>
                                    </div>:
                                    <div className='comment-all'>
                                        <div className='customer-views'>
                                            <div className='customer-header'>Customer Reviews</div>
                                            <div className='customer-show'>
                                                <span className='rate'><Rate allowHalf disabled defaultValue={this.props.item.commentLevel?((this.props.item.commentLevel - Math.floor(this.props.item.commentLevel)) >= 0.5 ?
                                                    (Math.floor(this.props.item.commentLevel)) + 0.5 : Math.floor(this.props.item.commentLevel)):0} /></span>
                                                <span className='word'>{this.props.item.commentLevel?this.props.item.commentLevel:0} out of 5</span>
                                            </div>
                                            <div className='global-rating'>{this.state.commentList.length} global ratings</div>
                                        </div>
                                        <div className='comment-list-show'>
                                            {this.state.commentList.length > 0?<div className='select-reviews'>Selected Reviews</div>:''}
                                            <div className='comment-list'>
                                        {this.state.commentList?this.state.commentListTemp.map((item,index)=>{
                                            return <div className='comment-item'>
                                                <div className='userName'>{item.userName}</div>
                                                <div className='rate'>
                                                    <Rate allowHalf disabled defaultValue={item.commentLevel?((item.commentLevel - Math.floor(item.commentLevel)) >= 0.5 ?
                                                        (Math.floor(item.commentLevel)) + 0.5 : Math.floor(item.commentLevel)):0} />
                                                    <span className='comment-time'>{dateFormat.formatDate(item.commentTime)}</span>
                                                </div>
                                                <div className='comment'>
                                                    {item.comment}
                                                </div>
                                            </div>
                                        }):''}
                                            </div>
                                            {this.state.showMoreDisplay?<div className='comment-list-show-more' onClick={this.showMore.bind(this)}><span className='comment-show-more'>show more</span><img src={showMorePic}/></div>:''}
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                    <div className='operation'>
                        {/*<Button shape='round' className='pick'
                                        onClick={this.pickGift.bind(this)}>{intl.get('giftInfoCom_button_pick')}</Button>
                                <Button shape='round' className='not-interested'
                                        onClick={() => this.notInterested()}>{intl.get('giftInfoCom_button_not_interested')}</Button>*/}
                        <div className='pick'>
                            {this.state.chooseButton ? <AwesomeButton type='primary'
                                                                      onPress={this.pickGift.bind(this)}>{this.state.chooseButton}</AwesomeButton>
                            :''}
                        </div>
                        {/*<div className='not-interested'>
                            <AwesomeButton type='primary'
                                           onPress={() => this.notInterested()}>{intl.get('giftInfoCom_button_not_interested')}</AwesomeButton>
                        </div>*/}
                    </div>
                </div>:''}
                {this.state.pickConfirmComShow?<PickConfirmCom item={this.props.item} hidden={this.pickConfirmComHidden.bind(this)}/>:''}
                {this.state.visible?<CloseConfirmCom item={this.props.item} confirmHidden={this.closeConfirmHidden.bind(this)}/>:''}
                {/*{this.state.installmentTipShow?<InstallmentTip hidden={this.installmentTipHidden.bind(this)}/>:''}*/}
            </div>
        )
    }

}