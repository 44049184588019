import React,{Component} from 'react'
import './index.less'
import intl from 'react-intl-universal'
import example from '../../images/12.png'
import { Rate } from 'antd';
import GiftInfoCom from '../../components/giftInfoComNew/GiftInfoCom'
import ReasonCom from "../../components/reasonCom/ReasonCom";
import InstallmentTip from "../../components/installmentTipCom/InstallmentTip";
import ajax from '../../utils/ajax'
import ivylabLogo  from '../../images/IVYLAB.png'
import pickLogo  from '../../images/Search for a gift.png'
import {pictureUrl} from '../../config/apiUrl'
import _ from 'lodash'
export default class GiftShow extends Component{
    constructor() {
        super();
        this.state={
            giftInfoComShow:false,
            reasonComShow:false,
            installmentShowValue:null,
            giftItem:null,
            giftList:[],
            giftListSet:null,
            title:'',
            reasonPopShow:false,
            smallPopShow:false,
            allOk:false
        }
    }

    componentDidMount() {
        this.getGiftList()
        this.getConfig()
        this.getUserConfig()
        //this.getInstallmentConfig()
    }

    getConfig(){
        ajax.post('/api/1.0/config/getConfig',{type: "word_change", subType: "product_list_title"}).then(response=>{
            if(response.code==='0'){
                this.setState({
                    title:response.data
                })
            }else{

            }

        })
    }

    getInstallmentConfig(){
        ajax.post('/api/1.0/config/getConfig',{type: "sys_set", subType: "installment_show_set"}).then(response=>{
            if(response.code==='0'){
                this.setState({
                    installmentShowValue:Number(response.data)
                },()=>{
                    if( Number(response.data) === 1){
                        ajax.post('/api/1.0/installmentLog/getList',{}).then(res=>{
                            if(res.code === '0'){
                                let list=res.data
                                if(list.length > 0){
                                    for(let i=0;i<list.length;i++){
                                        let item=list[i]
                                        for(let j=0;j<this.state.giftList.length;j++){
                                            let subItem=this.state.giftList[j]
                                            if(item.giftCode === subItem.giftCode){
                                                if(item.showResult === 1){
                                                    subItem.installmentShow=true
                                                }else{
                                                    subItem.installmentShow=false
                                                }
                                                break;
                                            }
                                        }
                                    }
                                    this.setState({
                                        allOk:true
                                    })
                                }else{
                                    let randomArr=[]
                                    for(let i=0;i<this.state.giftList.length;i++){
                                        let r=Math.random()
                                        randomArr.push(r)
                                    }
                                    let sortedArr=_.sortBy(randomArr,obj=>{return obj})
                                    let randomAver=sortedArr[Math.ceil(randomArr.length/2)]
                                    for(let i=0;i<this.state.giftList.length;i++){
                                        let item=this.state.giftList[i]
                                        let randomV=randomArr[i]
                                        let installmentV
                                        if(randomV < randomAver){
                                            item.installmentShow=false
                                            installmentV=0
                                        }else{
                                            item.installmentShow=true
                                            installmentV=1
                                        }
                                        ajax.post('/api/1.0/installmentLog/add',{giftCode:item.giftCode,
                                            showResult:installmentV}).then(res=>{

                                        })
                                    }
                                    this.setState({
                                        allOk:true
                                    })
                                }
                            }
                        })

                    }else{
                        this.setState({
                            allOk:true
                        })
                    }

                })
            }

        })
    }

    getUserConfig(){
        ajax.post('/api/1.0/user/userConfig',{}).then(response=>{
            if(response.code === '0'){
                 let reasonPop=response.data.popWinResult
                let smallPop=response.data.smallPopWinResult
                let r1,r2
                if(reasonPop === 'show'){
                    r1=true
                }else{
                    r1=false
                }

                if(smallPop === 'show'){
                    r2=true
                }else{
                    r2=false
                }
                this.setState({
                    reasonPopShow:r1,
                    smallPopShow:r2
                })
            }
        })
    }

    getGiftList(){
        ajax.post('/api/1.0/gift/list').then(response=>{
            if(response.code==='0'){
                this.setState({
                    giftList:response.data
                },()=>{
                    let newGiftList=[]
                    let giftListSet=[]
                    for(let i=0;i<this.state.giftList.length;i++){
                        let item=this.state.giftList[i]
                        let json=JSON.parse(item.giftPicture);
                        let otherJson=JSON.parse(item.otherPicture);
                        //console.log('heh222e')
                        //console.dir(json[0])
                        //item.mainPic=json.shift()
                        item.mainPic=json[0]
                        item.pictures=json
                        item.otherPictures=otherJson
                        newGiftList.push(item)
                    }

                   // newGiftList=newGiftList.reverse();
                    for (let i = 0; i < newGiftList.length; i += 3) {
                        // Use slice to get a portion of the one-dimensional array
                        const subArray = newGiftList.slice(i, i + 3);
                        // Push the subarray into the two-dimensional array
                        giftListSet.push(subArray);
                    }
                    this.setState({
                        giftList:newGiftList,
                        giftListSet:giftListSet
                    },()=>{
                        this.getInstallmentConfig()
                    })
                })
            }
        })
    }
    
    //商品展示
    giftInfoShow(item){
        //判断已经打开几次
        let count=localStorage.getItem(item.giftCode+'ivy2020')
        count=(count===null||count===undefined)?0:parseInt(count)
        if(count ===0 || !this.state.reasonPopShow){
            this.setState({
                giftItem:item,
                giftInfoComShow:true
            })
        }else{
            this.setState({
                giftItem:item,
                reasonComShow:true
            })
        }
        localStorage.setItem(item.giftCode+'ivy2020',''+(count+1))
        ajax.post('/api/1.0/browseLog/add',{giftCode:item.giftCode}).then(response=>{

        })
    }

    reasonComHidden(){
        this.setState({
            giftInfoComShow:true,
            reasonComShow:false
        })
    }

    giftInfoHidden(){
        this.setState({
            giftInfoComShow:false
        })
    }

    render() {
        return(
            <div className='giftShowView'>
                <div className='content'>
                    <img className='logo' src={ivylabLogo}/>
                    {/*<img className='title' src={pickLogo}/>*/}

                    <div className='title' dangerouslySetInnerHTML={{__html:this.state.title}}></div>
                </div>
                <div className='product-list-show'>
                    {this.state.giftList && this.state.allOk?<div className='gift-list'>
                        {this.state.giftList.map((item,index)=>{
                            return <div key={index} className='gift-item' onClick={this.giftInfoShow.bind(this,item)}>
                                {item.installmentShow?<div className='installments'>Installments</div>:''}
                                <img src={item.mainPic}/>

                                <div className='gift-introduce'>
                                    <div className='gift-name'>{item.giftName}</div>
                                    <div className='gift-price'><span className='word'>Price:</span> <span className='price'>${item.price}</span></div>
                                    <div className='gift-rate'><Rate allowHalf disabled value={item.commentLevel?((item.commentLevel - Math.floor(item.commentLevel)) >= 0.5 ?
                                        (Math.floor(item.commentLevel)) + 0.5 : Math.floor(item.commentLevel)):0} /></div>
                                </div>
                            </div>
                        })}
                    </div>:''}
                </div>
                {this.state.giftInfoComShow?<GiftInfoCom item={this.state.giftItem}
                                                         smallPopShow={this.state.smallPopShow}
                                                         installmentShowValue={this.state.installmentShowValue}
                                                         hidden={this.giftInfoHidden.bind(this)}/>:''}
                {this.state.reasonComShow?<ReasonCom item={this.state.giftItem}  hidden={this.reasonComHidden.bind(this)}/>:''}
            </div>
        )
    }

}

