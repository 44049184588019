import React,{Component} from "react";
import './index.less'
import {Button} from "antd";
import intl from 'react-intl-universal'
import ajax from "../../utils/ajax";
import {AwesomeButton} from 'react-awesome-button'
import 'react-awesome-button/dist/themes/theme-rickiest.css';
export default class PickConfirmCom extends Component{
    constructor() {
        super();
        this.state={
           sending:''
        }
    }
    confirm(){
        if(this.state.sending){
            return
        }
        this.setState({
            sending:'Sending'
        })
        ajax.post('/api/1.0/choose/add',{giftCode:this.props.item.giftCode,operateType:1}).then(response=>{
            if(response.code === '0'){
                window.location.href='/finish'
            }

        })

    }

    back(){
        this.props.hidden()
    }

    render() {
        return(
            <div className='pickConfirmCom'>
                <div className='pickConfirm-content'>
                    <div className='confirm-title'>
                        {intl.get('pickConfirmCom_title')}
                    </div>
                    <div className='confirm-content'>
                        {intl.get('pickConfirmCom_content')}
                    </div>
                    <div className='confirm-operation'>
                       {/*<Button className='yes' onClick={this.confirm.bind(this)}>{intl.get('pickConfirmCom_button_yes')}</Button>
                        <Button className='no' onClick={this.back.bind(this)}>{intl.get('pickConfirmCom_button_no')}</Button>*/}
                        <div className='yes'>
                            <AwesomeButton  onPress={this.confirm.bind(this)}>{!this.state.sending?intl.get('pickConfirmCom_button_yes'):this.state.sending}</AwesomeButton>
                        </div>
                        <div className='no'>
                            <AwesomeButton  onPress={this.back.bind(this)}>{intl.get('pickConfirmCom_button_no')}</AwesomeButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}