import React, { Component } from 'react'
import { Route, Redirect } from 'react-router'
import {
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import intl from 'react-intl-universal';

import Loadable from 'react-loadable';


import MainPage from './pages/mainPage/MainPage'
import GiftShow from './pages/giftShow/GiftShow'
import Finish from "./pages/finish/Finish";
import FacebookPage from "./pages/facebookPage/facebookPage";
import KlineShow from "./pages/klineShow/KlineShow";


const locales = {
    "en-US": require('./locales/en-US.json'),
    "zh-CN": require('./locales/zh-CN.json'),
};

class App extends Component {
    state = { initDone: false }

    componentDidMount() {
        this.loadLocales();
    }

    loadLocales() {
        // react-intl-universal 是单例模式, 只应该实例化一次
        var nowDate = new Date();
        var plus = nowDate.getTimezoneOffset();
        if (!window.localStorage.getItem("lang")) {
        if (window.navigator.language == "zh-CN" || window.navigator.language == "zh-TW") {
            localStorage.setItem("lang", 'zh-CN')
        } else {
            localStorage.setItem("lang", 'en-US')
        }
        }
        /*if (!window.localStorage.getItem("lang")) {
            localStorage.setItem("lang", plus == -480 ? 'zh-CN' : 'en-US')
        }*/
        intl.init({
            currentLocale: window.localStorage.getItem("lang") || ((window.navigator.language=="zh-CN"||window.navigator.language=="zh-TW") ? 'zh-CN' : 'en-US'), // TODO: determine locale here
            locales,
        }).then(() => {
            this.setState({ initDone: true });
        });
    }

    render() {
        return (
            this.state.initDone &&
            <Router>
                <Switch>
                    <Route exact path="/" component={MainPage} />
                    <Route exact path='/giftShow' component={GiftShow}/>
                    <Route exact path='/finish' component={Finish}/>
                    {/*<Route exact path='/facebook' component={FacebookPage}/>
                    <Route exact path='/kline' component={KlineShow}/>*/}
                </Switch>
            </Router>
        )
    }
}

export default App
