const server_host = "https://support.quantmktlab.com";
//const server_host = "http://localhost:8081";
//const server_host='http://150.109.50.155:8081'
const pictureUrl="https://support.quantmktlab.com:8088/picture/";
const redirectIndex = "https://cos.cityhuz.com";
const OAUTH_IDENTIFIER = "35186f80165b4ada0723eb9df1b2e1c4";
const clientIdentifier = "4eb884c752114c9cd4e706e0874dda66";
const daDianAppId = '211d8b77466da9ae47ff6c2e6eaa6a26'
const googleTranslateHost="http://47.245.57.234:8088"
//const googleTranslateHost="http://localhost:8088"
export { server_host,pictureUrl, redirectIndex, OAUTH_IDENTIFIER, clientIdentifier,daDianAppId,googleTranslateHost };
