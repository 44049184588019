import React,{Component} from 'react'
import './index.less'
import intl from 'react-intl-universal'
import ajax from '../../utils/ajax'
import { Button,Input,message } from 'antd';
import {AwesomeButton} from 'react-awesome-button'
import 'react-awesome-button/dist/themes/theme-rickiest.css';
const { TextArea } = Input;

export default class ReasonCom extends Component{
    constructor() {
        super();
        this.state={
            choose:null,
            subChoose:null,
            items:[

            ],
            subItems:[

            ],
            title:'Why are you looking at this wristband again?',
            subTitle:'The reason to drop it :',
            remark:null,
            subRemark:null,
            sending:'',
            subReason_1:null,
            subReason_2:null,
            subReason_3:null,
            subItemShow:false,
            startTime:new Date().getTime(),
            hasSubItem:false
        }
    }

    componentDidMount() {
        this.getMainReason()
        this.getSubReason_1()
        this.getSubReason_2()
        this.getSubReason_3()
    }

    getMainReason(){
        ajax.post('/api/1.0/reasonSet/getList',{}).then(response=>{
            if(response.code === '0'){
                this.setState({
                    items:response.data.list
                })
            }
        })
    }

    getSubReason_1(){
        ajax.post('/api/1.0/reasonSet/getSubList',{mainId:1}).then(response=>{
            if(response.code === '0'){
                this.setState({
                    subReason_1:{
                        mainCode:1,
                        subItems:response.data.list
                    }
                })
            }
        })
    }

    getSubReason_2(){
        ajax.post('/api/1.0/reasonSet/getSubList',{mainId:2}).then(response=>{
            if(response.code === '0'){
                this.setState({
                    subReason_2:{
                        mainCode:2,
                        subItems:response.data.list
                    }
                })
            }
        })
    }

    getSubReason_3(){
        ajax.post('/api/1.0/reasonSet/getSubList',{mainId:3}).then(response=>{
            if(response.code === '0'){
                let temp=new Array(3)
                let data=response.data.list
                for(let i=0;i<data.length;i++){
                    let item=data[i]
                    temp[item.code]=item
                }
                this.setState({
                    subReason_3:{
                        mainCode:3,
                        subItems:temp
                    }
                })
            }
        })
    }

    /*setItems(){
        let items=[
            {index:0,item:'I mistakenly clicked this'},
            {index:1,item:'I forgot some information'},
            {index:2,item:'I want to drop this item from consideration'},
            {index:3,item:'I want to obtain more information of this item'}
        ]
        let subItems=[{index:0,item:'This one is similar to the one I just searched'},
            {index:2,item:'Fewer items are easier for my final choice'}]
        let shuffle1 = this.shuffle(items);
        shuffle1.push({index:4,item:'Other'})
        let shuffle2=this.shuffle(subItems)
        shuffle2.push({index:1,item:'Other'})
        this.setState({
            items:shuffle1,
            subItems:shuffle2
        })
    }
*/
    shuffle(arr) {
        for (let i=arr.length-1; i>=0; i--) {
            let rIndex = Math.floor(Math.random()*(i+1));
            // 打印交换值
            // console.log(i, rIndex);
            let temp = arr[rIndex];
            arr[rIndex] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }

    chooseItem(item){
        let code=item.code
        let subItems
        let subItemShow=false
        let subTitle
        this.setState({
            choose:code
        })
        if(code === 1){
            subItems=this.state.subReason_1.subItems
            subItemShow=true
            // subTitle='What did you forget about this wristband?'

        }else if(code === 2){
            subItems=this.state.subReason_2.subItems
            subItemShow=true

            // subTitle='Why do you want to compare this option with other options you looked at?'

        }else if(code === 3){
            subItems=this.state.subReason_3.subItems
            subItemShow=true
            // subTitle='This wristband is better than other wristbands I have seen?'

        }else if(code === 4){
            // subTitle=''
        }
        this.setState({
            subItems:subItems,
            hasSubItem:subItemShow
        })
    }

    subChooseItem(item){
        this.setState({
            subChoose:item.code
        })
    }

    changeRemark(e){
        let value=e.target.value
        this.setState({
            remark:value
        })
    }

    submitData(){
        if(this.state.hasSubItem){
            let code=this.state.choose
            let subTitle
            if(code === 1){
                subTitle='What did you forget about this wristband?'
            }else if(code === 2){
                subTitle='Why do you want to compare this option with other options you looked at?'
            }else if(code === 3){
                subTitle='This wristband is better than other wristbands I have seen?'
            }else if(code === 4){
                subTitle=''
            }
            this.setState({
                subTitle:subTitle,
                subItemShow:true,
                hasSubItem:false
            })
            return;
        }
        if(this.state.sending){
            return;
        }
        this.setState({
            sending:'Sending'
        })
        if(this.state.choose===null||this.state.choose===undefined){
            message.warn("please complete information!")
            this.setState({
                sending:''
            })
            return
        }
        if(this.state.subItemShow && (this.state.subChoose === null || this.state.subChoose === undefined)){
            message.warn("please complete information!")
            this.setState({
                sending:''
            })
            return
        }

        if(this.state.choose===4&&!this.state.remark){
            message.warn("please complete information!")
            this.setState({
                sending:''
            })
            return;
        }
        if(((this.state.subChoose===2 && this.state.choose ===1) || (this.state.subChoose===1 && this.state.choose ===2))&&!this.state.remark){
            message.warn("please complete information!")
            this.setState({
                sending:''
            })
            return
        }
        let endTime=new Date().getTime()
        let duration=(endTime - this.state.startTime)/1000
        ajax.post('/api/1.0/reasonLog/add',{giftCode:this.props.item.giftCode,
            reasonType:this.state.choose,subReasonType:this.state.subChoose,remark:this.state.remark,duration:duration}).then(response=>{
            if(response.code==='0'){
                message.success('success')
                this.setState({
                    sending:''
                })
            }else{
                this.setState({
                    sending:''
                })
            }
            this.props.hidden()
        })
    }

    render() {
        return (
            <div className='reasonCom'>
            <div className='reasonComShow'>
            <div className='reason-title'>
            {!this.state.subItemShow?this.state.title:this.state.subTitle}
    </div>
        <div className='reason-content'>
        { (!this.state.choose || !this.state.subItemShow)?
    <div className='reason-items'>
            {this.state.items?this.state.items.map((item,index)=>{
                    return <div key={index} className='reason-item' onClick={this.chooseItem.bind(this,item)}>
                        <div className='choose-label'
                    style={{background:this.state.choose===item.code?'#6DBC2F':''}}
                ></div>
                    <div className='word'>{item.message}</div>
                        </div>
                }):''}
            </div>
    :<div className='reason-items'>
            {this.state.subItems.map((item,index)=>{
                    return <div key={index} className='reason-item' onClick={this.subChooseItem.bind(this,item)}>
                        <div className='choose-label'
                    style={{background:this.state.subChoose===item.code?'#6DBC2F':''}}
                ></div>
                    <div className='word'>{item.message}</div>
                        </div>
                })}
            </div>}
        {this.state.choose===4||(this.state.subChoose===2 && this.state.choose ===1) || (this.state.subChoose===1 && this.state.choose ===2)?<TextArea rows={2} maxLength={200} onChange={e=>this.changeRemark(e)}/>:''}
            {/*
                          <Button className='button-submit' onClick={this.submitData.bind(this)}>submit</Button>
*/}
        <AwesomeButton className='button-submit' type='primary' onPress={this.submitData.bind(this)}>{!this.state.sending?'submit':this.state.sending}</AwesomeButton>
        </div>
        </div>
        </div>
        )
    }

}