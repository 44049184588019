import React,{Component} from 'react'
import './index.less'
import intl from 'react-intl-universal'
import ajax from '../../utils/ajax'
import { Button,Input,message } from 'antd';
import {AwesomeButton} from 'react-awesome-button'
import 'react-awesome-button/dist/themes/theme-rickiest.css';
const { TextArea } = Input;

export default class ReasonCom extends Component{
    constructor() {
        super();
        this.state={
        }
    }

    componentDidMount() {

    }

    confrimYes(){
        ajax.post('/api/1.0/giftCloseChoose/add',{giftCode:this.props.item.giftCode,value:1}).then(response=>{

        })
        this.props.confirmHidden()
    }

    confrimNo(){
        this.props.confirmHidden()
        ajax.post('/api/1.0/giftCloseChoose/add',{giftCode:this.props.item.giftCode,value:0}).then(response=>{

        })
    }

    confrimNotSure(){
        this.props.confirmHidden()
        ajax.post('/api/1.0/giftCloseChoose/add',{giftCode:this.props.item.giftCode,value:2}).then(response=>{

        })
    }

    render() {
        return (
            <div className='closeConfirmCom'>
                <div className='closeConfirmComShow'>
                    <div className='close-all-show'>
                        <div className='close-title'>
                            Are you still considering this item for yourself?
                        </div>
                        <div className='close-content'>
                            <div className='close-yes'  onClick={this.confrimYes.bind(this)}>YES</div>
                            <div className='close-no'  onClick={this.confrimNo.bind(this)}>NO</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}