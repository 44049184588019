import React,{Component} from "react";
import {Input,Button,message} from 'antd'

import './index.less'
import {number} from "prop-types";

export default class KlineShow extends Component{
    constructor() {
        super();
        this.state={
            studentId:'',
            email:'',
            sending:'',
            unable:false,
            mainPage_title:'',
            mainPage_introduce:'',
            studentId_placeholder:'',
            email_placeholder:'',
            button_text:'',
            visible:true,
            currentKlineNum:1,
            kline_pic:'trb_1d_',
            intervalValue:500,
            intervalId:null
        }
    }

    componentDidMount() {

    }

    openInterval(){
        const id = setInterval(() => {
            console.log('Interval fired!');
            this.processGo()
        }, Number(this.state.intervalValue));
        this.setState({
            intervalId:id
        })

    }

    stopInterval(){
        clearInterval(this.state.intervalId)
    }

    processGo(){
        let currentLineNum = this.state.currentKlineNum + 1
        this.setState({
            currentKlineNum:currentLineNum
        })
    }

    processBack(){
        if(this.state.currentKlineNum === 1){
            return
        }
        let currentLineNum = this.state.currentKlineNum - 1
        this.setState({
            currentKlineNum:currentLineNum
        })
    }

    onChange(field, e) {
        let valStr = e.target.value;
        this.setState({
            [field]: valStr,
            currentKlineNum:1
        });
    };
    render() {

        return (
            <div className='klinePageView'>
                <div className='content'>
                    <img src={require('../../testsPics/pics/'+this.state.kline_pic+this.state.currentKlineNum+'.jpg')}/>
                    <div className='file_name'>{this.state.kline_pic+this.state.currentKlineNum+'.jpg'}</div>
                    <div className='button'>
                        <div className='go' onClick={this.processGo.bind(this)}>前进</div>
                        <div className='back' onClick={this.processBack.bind(this)}>后退</div>
                    </div>

                    <div className='button_l'>
                        <div className='go' onClick={this.openInterval.bind(this)}>开启自动播放</div>
                        <div className='back' onClick={this.stopInterval.bind(this)}>关闭自动播放</div>
                    </div>

                    <div>
                        自动播放周期：<select onChange={this.onChange.bind(this, 'intervalValue')}>
                        <option value={500}>0.5s</option>
                        <option value={1000}>1s</option>
                        <option value={3000}>3s</option>
                        <option value={5000}>5s</option>
                    </select>
                    </div>

                    <div>
                        切换类型：<select onChange={this.onChange.bind(this, 'kline_pic')}>
                        <option value={'trb_1d_'}>trb_1d</option>
                        <option value={'trb_15m_'}>trb_15m</option>
                        <option value={'btc_1d_'}>btc_1d</option>
                    </select>
                    </div>

                </div>
            </div>
        )
    }
}
