import axios from 'axios';
import { server_host, redirectIndex,daDianAppId, OAUTH_IDENTIFIER } from '../config/apiUrl'
axios.defaults.withCredentials = true;
const token = window.localStorage.getItem("access_token");
if (!token) {
} else {
}
const axiosInstance = axios.create({
  // baseURL: 'http://192.168.10.132:91',
  baseURL: server_host,
  transformRequest: [function (data) {
    // data = Qs.stringify(data);
    data = JSON.stringify(data);
    //console.log(data);
    return data;

  }],
});


// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json;charset=utf-8', 'X-Requested-With': 'XMLHttpRequest', 'token': window.localStorage.getItem("userCode")
    }
    return config;
  },
  req =>
    // Do something before request is sent
    req
  , (error) => {
    // Do something with request error
    Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(

  (response) => {
    if (response.data.code === '800' || response.data.code === '408' || response.data.code === '801' || response.data.code === '802' || response.data.code === '805' || response.data.code === '831' || response.data.code === '600' || response.data.code === '504') {
      localStorage.clear();
      window.location='/login'
    } else if(response.data.code === '920'){
      window.open('https://www.ddpurse.com/openapi/set_pay_config?app_id=' + daDianAppId + '&redirect_uri=' + redirectIndex, '_self')
    }
    return response.data;
  }
  , (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          window.location.href = error.response.data.url;
          break;
        case 404:
          error.message = '请求错误,未找到该资源';
          break;
        case 500:
          error.message = '服务器端出错';
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
      alert(error.message);
    }
    return Promise.reject(error.data); // 返回接口返回的错误信息
  },
);

export default axiosInstance;
